//
// Existing item picker
//

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import * as SharedStyle from '../../../../shared-style';
import { MdPerson, MdShoppingCart } from 'react-icons/md';


const styleIcon = { margin: '0.5em' };
const containerStyle = {
  fontSize: '1.3em',
  padding: '1em',
  margin: '0.5em',
  backgroundColor: SharedStyle.PRIMARY_COLOR.main,
  color: SharedStyle.COLORS.white,
  borderRadius: '0.3em',
  boxShadow: '0px 0px 2px 0px rgba(0,0,0,0.5)',
  cursor: 'pointer',
};
const labelPriceStyle = {fontSize: '0.8em'};

export default class XDLineItemListItem extends Component {

  constructor(props, context) {
    super(props, context);
  }

  toCurrencyEmptyZero(value) {
    if (!value) {
      return '';
    }
    return Intl.NumberFormat('en-CA', {style: 'currency', currency: 'CAD', maximumFractionDigits: 5}).format(value);
  }

  toNumberEmptyZero(value) {
    if (!value) {
      return '';
    }
    return Intl.NumberFormat('en-CA', {maximumFractionDigits: 2}).format(value);
  }

  render() {
    let {
      props: {state, lineItem, onClick, style: userStyle},
    } = this;
    let baseCost = this.toCurrencyEmptyZero(lineItem.get('baseCost'));
    let costPerUnit = this.toCurrencyEmptyZero(lineItem.get('costPerUnit'));
    let minLengthArea = this.toNumberEmptyZero(lineItem.get('minLengthArea'));
    let costUnit = lineItem.get('unit');
    let minLengthAreaUnit = lineItem.get('minLengthAreaUnit');
    if (costUnit === 'hours') {
      costUnit = 'hr';
    }
    let costPerUnitDisplay = costPerUnit ? `${costPerUnit}/${costUnit}` : '';
    let displayCost = `${baseCost} ${baseCost && costPerUnitDisplay && '+'} ${costPerUnitDisplay}`;
    let lengthArea = !!minLengthArea ? `${minLengthArea} ${minLengthAreaUnit||'cm'}` : '';
    const isLabour = costUnit === 'hr';
    return (
      <div
        style={{
          ...containerStyle,
          ...userStyle,
        }}
        onClick={onClick}
      >
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
          {
            isLabour ?
              <MdPerson style={styleIcon} /> :
              <MdShoppingCart style={styleIcon} />
          }
          <div>
            <span>{lineItem.get('name')}</span>
            <div style={labelPriceStyle}>
              {displayCost}
            </div>
            {!isLabour && lengthArea && <div style={labelPriceStyle}>
              {lengthArea}
            </div>}
          </div>
        </div>
      </div>
    );
  }
}

XDLineItemListItem.propTypes = {
  state: PropTypes.object.isRequired,
  lineItem: PropTypes.object.isRequired,
  onClick: PropTypes.func, // onClick(e: MouseEventHandler)
  style: PropTypes.object,
};

XDLineItemListItem.contextTypes = {
  catalog: PropTypes.object.isRequired,
  translator: PropTypes.object.isRequired,
};
