import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Map, List, fromJS} from 'immutable';
import * as SharedStyle from '../../../../shared-style';
import {TiPlus, TiDelete, TiUpload} from 'react-icons/ti';
import {FaTrash, FaEye, FaLink, FaUnlink} from 'react-icons/fa';
import {FormTextInput, FormNumberInput, FormCheckbox, Button, FormSelect} from '../../../style/export';
import XDLineItemCatalog from './xd-line-item-catalog';
import convert from 'convert-units';



const styleHoverColor = {color: SharedStyle.SECONDARY_COLOR.main};
const styleAddLabel = {fontSize: '10px', marginLeft: '5px'};
const labelStyle = {fontSize: '1.3em', cursor: 'pointer', textAlign: 'center'};
const labelHoverStyle = {...labelStyle, ...styleHoverColor};
const rowMargin = {marginTop: '1em', marginBottom: '1em'};

export default class XDLineItemEditor extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = this.initState(this.props.state);
  }

  initState(state) {
    let { lineItemID, state: appState } = this.props;
    let lineItem = appState.getIn(['scene', 'lineItems', lineItemID]);
    let formData = new Map({
      name: lineItem.get('name'),
      baseCost: lineItem.get('baseCost'),
      costPerUnit: lineItem.get('costPerUnit'),
      unit: lineItem.get('unit'),
      minLengthArea: lineItem.get('minLengthArea'),
      minLengthAreaUnit: lineItem.get('minLengthAreaUnit'),
    });
    return {
      formData: formData,
      showCatalog: false,
    };
  }

  reset() {
    this.setState(this.initState(this.props.state));
  }

  updateField(field, value) {
    let { formData } = this.state;
    formData = formData.set(field, value);
    this.setState({ formData });
  }

  updateUnit(unit) {
    let { formData } = this.state;
    const prevUnit = formData.get('unit');
    const costPerUnit = formData.get('costPerUnit');
    const newCostPerUnit = convert(costPerUnit).from(unit).to(prevUnit);
    formData = formData.set('unit', unit);
    formData = formData.set('costPerUnit', newCostPerUnit);
    this.setState({ formData });
  }

  updateUnitMinLengthArea(unit) {
    let { formData } = this.state;
    const prevUnit = formData.get('minLengthAreaUnit');
    const minLengthArea = formData.get('minLengthArea');
    const newMinLengthArea = convert(minLengthArea).from(unit).to(prevUnit);
    formData = formData.set('minLengthAreaUnit', unit);
    formData = formData.set('minLengthArea', newMinLengthArea);
    this.setState({ formData });
  }

  showCatalog() {
    this.setState({ showCatalog: true });
  }

  hideCatalog() {
    this.setState({ showCatalog: false });
  }

  importLineItemFromCatalog(lineItem, lineItemType) {
    let { onSave } = this.props;
    let { formData } = this.state;
    if (!lineItem) {
      throw new Error('No line item provided to import');
    }
    if (lineItemType !== 'material' && lineItemType !== 'labour') {
      throw new Error(`Invalid line item type: ${lineItemType}`);
    }
    formData = formData.merge({
      name: lineItem.get('name'),
      baseCost: lineItem.get('baseCost'),
      costPerUnit: lineItem.get('costPerUnit'),
      unit: lineItemType === 'material' ? lineItem.get('unit') : 'hours',
      minLengthArea: lineItem.get('minLengthArea'),
      minLengthAreaUnit: lineItemType === 'material' ? lineItem.get('minLengthAreaUnit') : undefined,
    });
    this.setState({ formData });
    this.hideCatalog();
    onSave(formData, false);
  }

  render() {
    let {
      state: {formData, showCatalog},
      props: {state: appState, lineItemID, onSave, onCancel, isNew},
    } = this;

    const unit = formData.get('unit') || appState.getIn(['scene', 'unit']);
    const isLabour = formData.get('unit') === 'hours';

    return ( showCatalog ?
      <div>
        <XDLineItemCatalog
          state={appState}
          onAdd={ (lineItem, type) => this.importLineItemFromCatalog(lineItem, type) }
          onCancel={ e => this.hideCatalog() }
        />
      </div> :
      <div>
        <div style={{ ...rowMargin, textAlign: 'center', fontSize: '1.25em' }}>
          { isNew ? 'Create new line item' : 'Edit line item' }
        </div>
        { isNew &&
          <div style={{ marginBottom: '1em' }}>
            <Button
              title="Import item from database catalog"
              onClick={ e => this.showCatalog() }
            >
              <TiUpload />
              Import from Catalog
            </Button>
          </div>
        }
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td>Name</td>
              <td>
                <FormTextInput
                  value={formData.get('name')}
                  onChange={ e => this.updateField('name', e.target.value) }
                  state={appState}
                />
              </td>
            </tr>
            <tr>
              <td>{/* Is Labour? */}</td>
              <td>
                <FormCheckbox
                  checked={isLabour}
                  onChange={ e => this.updateField('unit', !!e.target.checked ? 'hours' : (appState.getIn(['scene', 'displayUnit']) || appState.getIn(['scene', 'unit']))) }
                  label="Is Labour?"
                />
              </td>
            </tr>
            {
              !isLabour &&
              <tr>
                <td>Base Cost</td>
                <td>
                  <FormNumberInput
                    value={formData.get('baseCost')}
                    onChange={ e => this.updateField('baseCost', e.target.value) }
                    min={0}
                    state={appState}
                    immediate={true}
                  />
                </td>
              </tr>
            }
            {
              !isLabour &&
              <tr>
                <td></td>
                <td style={{ color: '#888', paddingBottom: '0.5rem', textShadow: 'none', fontSize: '0.9em' }}>
                  Flat cost of the item, per quantity applied to a wall/area.
                  For example, if the base cost is $10, and the quantity is 5, the total flat cost will be $50.
                </td>
              </tr>
            }
            <tr>
              {/* TODO: Should this exist on items/holes? */}
              <td>
                {
                  isLabour ?
                    'Cost / hour' :
                    `Cost / ${unit}`
                }
              </td>
              <td>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '0.5em',
                }}>
                  <FormNumberInput
                    value={formData.get('costPerUnit')}
                    onChange={ e => this.updateField('costPerUnit', e.target.value) }
                    min={0}
                    state={appState}
                    immediate={true}
                  />
                  {
                    !isLabour &&
                    <FormSelect
                      value={formData.get('unit') || 'ft'}
                      onChange={ e => this.updateUnit(e.target.value) }
                    >
                      <option value="cm">Centimetres</option>
                      <option value="ft">Feet/In</option>
                      <option value="m">Metres</option>
                    </FormSelect>
                  }
                </div>
              </td>
            </tr>
            {
              !isLabour &&
              <tr>
                <td></td>
                <td style={{ color: '#888', paddingBottom: '0.5rem', textShadow: 'none', fontSize: '0.9em' }}>
                  The cost per unit of this item.
                  For example, if cost/ft is $1.50, and used on a wall of 10ft, the total cost will be $15.00 &times; Qty for that wall.
                </td>
              </tr>
            }
            {
              !isLabour &&
              <tr>
                {/* TODO: Should this exist on items/holes? */}
                <td>Length / Area</td>
                <td>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '0.5em',
                  }}>
                    <FormNumberInput
                      value={formData.get('minLengthArea')}
                      onChange={ e => this.updateField('minLengthArea', e.target.value) }
                      min={0}
                      state={appState}
                      immediate={true}
                    />
                    {
                      !isLabour &&
                      <FormSelect
                        value={formData.get('minLengthAreaUnit') || 'ft'}
                        onChange={ e => this.updateUnitMinLengthArea(e.target.value) }
                      >
                        <option value="cm">Centimetres</option>
                        <option value="ft">Feet/In</option>
                        <option value="m">Metres</option>
                      </FormSelect>
                    }
                  </div>
                </td>
              </tr>
            }
            {
              !isLabour &&
              <tr>
                <td></td>
                <td style={{ color: '#888', paddingBottom: '0.5rem', textShadow: 'none', fontSize: '0.9em' }}>
                  The length or area of this item is used to determine quantity automatically.
                  For example, if the wall length is 10ft, and the item length is 4ft, the quantity will be 2.5 for that wall.
                  Total quantities are rounded up to the nearest whole number after all walls and areas are considered.
                </td>
              </tr>
            }
          </tbody>
        </table>
        <div style={rowMargin}>
          <Button onClick={ e => onSave(formData, false) }>
            <TiPlus />
            <b style={styleAddLabel}>
              { isNew ? 'Save Item' : 'Save Item (update all existing)' }
            </b>
          </Button>
        </div>
        {
          !isNew &&
          <div style={rowMargin}>
            <Button onClick={ e => onSave(formData, true) }>
              <TiPlus />
              <b style={styleAddLabel}>Save Item (as new copy)</b>
            </Button>
          </div>
        }
        <div style={rowMargin}>
          <Button onClick={ e => onCancel() }>
            <TiDelete />
            <b style={styleAddLabel}>Cancel</b>
          </Button>
        </div>
      </div>
    )
  }
}

XDLineItemEditor.propTypes = {
  state: PropTypes.object.isRequired,
  lineItemID: PropTypes.string.isRequired,
  isNew: PropTypes.bool,
  onSave: PropTypes.func, // onSave(formData, saveAsCopy = false)
  onCancel: PropTypes.func, // onCancel()
};

XDLineItemEditor.contextTypes = {
  catalog: PropTypes.object.isRequired,
  translator: PropTypes.object.isRequired,
};
