import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { ReactSVGPanZoom, TOOL_NONE, TOOL_PAN, TOOL_ZOOM_IN, TOOL_ZOOM_OUT, TOOL_AUTO, pan } from 'react-svg-pan-zoom';
import * as constants from '../../constants';
import State from './state';
import * as SharedStyle from '../../shared-style';
import { RulerX, RulerY } from './export';
import { Button, Dialog, FormCheckbox, FormNumberInput } from '../style/export';
import convert from 'convert-units';
import XDFeetInchesInput from '../style/xd-feet-inches-input';
import { GeometryUtils } from '../../utils/export';
import { decimalToFtInchesString } from '../../utils/xd-math';

function mode2Tool(mode) {
  switch (mode) {
    case constants.MODE_2D_PAN:
      return TOOL_PAN;
    case constants.MODE_2D_ZOOM_IN:
      return TOOL_ZOOM_IN;
    case constants.MODE_2D_ZOOM_OUT:
      return TOOL_ZOOM_OUT;
    case constants.MODE_IDLE:
      return TOOL_AUTO;
    default:
      return TOOL_NONE;
  }
}

function mode2PointerEvents(mode) {
  switch (mode) {
    case constants.MODE_DRAWING_LINE:
    case constants.MODE_DRAWING_HOLE:
    case constants.MODE_DRAWING_ITEM:
    case constants.MODE_DRAGGING_HOLE:
    case constants.MODE_DRAGGING_ITEM:
    case constants.MODE_DRAGGING_LINE:
    case constants.MODE_DRAGGING_VERTEX:
      return { pointerEvents: 'none' };

    default:
      return {};
  }
}

function mode2Cursor(mode) {
  switch (mode) {
    case constants.MODE_DRAGGING_HOLE:
    case constants.MODE_DRAGGING_LINE:
    case constants.MODE_DRAGGING_VERTEX:
    case constants.MODE_DRAGGING_ITEM:
      return { cursor: 'move' };

    case constants.MODE_ROTATING_ITEM:
      return { cursor: 'ew-resize' };

    case constants.MODE_WAITING_DRAWING_LINE:
    case constants.MODE_DRAWING_LINE:
      return { cursor: 'crosshair' };
    default:
      return { cursor: 'default' };
  }
}

function mode2DetectAutopan(mode) {
  switch (mode) {
    case constants.MODE_DRAWING_LINE:
    case constants.MODE_DRAGGING_LINE:
    case constants.MODE_DRAGGING_VERTEX:
    case constants.MODE_DRAGGING_HOLE:
    case constants.MODE_DRAGGING_ITEM:
    case constants.MODE_DRAWING_HOLE:
    case constants.MODE_DRAWING_ITEM:
      return true;

    default:
      return false;
  }
}

function extractElementData(node) {
  while (!node.attributes.getNamedItem('data-element-root') && node.tagName !== 'svg') {
    node = node.parentNode;
  }
  if (node.tagName === 'svg') return null;

  return {
    part: node.attributes.getNamedItem('data-part') ? node.attributes.getNamedItem('data-part').value : undefined,
    layer: node.attributes.getNamedItem('data-layer').value,
    prototype: node.attributes.getNamedItem('data-prototype').value,
    selected: node.attributes.getNamedItem('data-selected').value === 'true',
    id: node.attributes.getNamedItem('data-id').value
  }
}

export default function Viewer2D(
  { state, width, height },
  { viewer2DActions, linesActions, holesActions, verticesActions, itemsActions, areaActions, projectActions, catalog }) {

  let { viewer2D, mode, scene, drawingSupport, designLock } = state;

  let layerID = scene.selectedLayer;

  let [lastTouchDown, setLastTouchDown] = useState(0);
  let [confirmLengthDialogProto, setConfirmLengthDialogProto] = useState(null);
  let [confirmLengthDialogValue, setConfirmLengthDialogValue] = useState(0);
  let [confirmLengthDialogAnchor, setConfirmLengthDialogAnchor] = useState('start'); // or 'end'

  const confirmLengthDialog = state.get('drawingConfirmationOpen');
  const confirmLengthChk = state.get('drawingConfirmationEnabled');

  //useEffect(() => {
  //  console.debug("MODE: ", mode);
  //}, [mode])

  let mapCursorPosition = ({ x, y }) => {
    return { x, y: -y + scene.height }
  };

  let panViewer = (dx, dy) => {
    onChangeValue(pan(viewer2D.toJS(), dx, dy));
  }

  let isSelecting = () => {
    const selected = scene.getIn(['layers', layerID, 'selected']);
    return selected.lines.size > 0 || selected.holes.size > 0 || selected.areas.size > 0 || selected.items.size > 0;
  }

  let isModeAllowed = () => {
    switch (mode) {
      case constants.MODE_IDLE:
      case constants.MODE_2D_PAN:
      case constants.MODE_2D_ZOOM_IN:
      case constants.MODE_2D_ZOOM_OUT:
        return true;
      default:
        return !designLock;
    }
  }

  let confirmLineDraw = (bFinishDrawing = false) => {
    let {
      protoLine, x, y, startX, startY, protoLength, layerID, snapMask, wasTouch, touchStart,
    } = confirmLengthDialogProto;
    // Get newX and newY by taking the vector from start to end and scaling it to the new length
    const confirmLength =
      convert(confirmLengthDialogValue)
        .from(scene.get('displayUnit'))
        .to(scene.get('unit'));
    if (confirmLength > 0) {
      if (confirmLengthDialogAnchor === 'start') {
        const [ newX, newY ] = [
          startX + (x - startX) * confirmLength / protoLength,
          startY + (y - startY) * confirmLength / protoLength,
        ];
        linesActions.endDrawingLine(newX, newY, snapMask);
        !bFinishDrawing && !wasTouch && // don't immedately start new line for touch
          linesActions.beginDrawingLine(layerID, newX, newY, snapMask);
      } else {
        const [ newX, newY ] = [
          x + (startX - x) * confirmLength / protoLength,
          y + (startY - y) * confirmLength / protoLength,
        ];
        linesActions.endDrawingLine(x, y, snapMask, newX, newY);
        !bFinishDrawing && !wasTouch && // don't immedately start new line for touch
          linesActions.beginDrawingLine(layerID, x, y, snapMask);
      }
    }
    projectActions.setDrawingConfirmationOpen(false);
    setConfirmLengthDialogProto(null);
    if (bFinishDrawing) {
      projectActions.setMode(constants.MODE_IDLE);
    }
  }

  let cancelLineDraw = (bReselectDrawTool = false) => {
    projectActions.undo();
    if (bReselectDrawTool) {
      linesActions.selectToolDrawingLine(drawingSupport.get('type'));
    }
    //setConfirmLengthDialog(false);
    projectActions.setDrawingConfirmationOpen(false);
    setConfirmLengthDialogProto(null);
  }

  let onMouseMove = (viewerEvent, wasTouch) => {
    //workaround that allow imageful component to work
    let evt = new Event('mousemove-planner-event');
    evt.viewerEvent = viewerEvent;
    document.dispatchEvent(evt);

    let { x, y } = mapCursorPosition(viewerEvent);

    projectActions.updateMouseCoord({ x, y });

    if (!isModeAllowed()) return;

    switch (mode) {
      case constants.MODE_DRAWING_LINE:
        linesActions.updateDrawingLine(x, y, state.snapMask);
        break;

      case constants.MODE_DRAWING_HOLE:
        holesActions.updateDrawingHole(layerID, x, y);
        break;

      case constants.MODE_DRAWING_ITEM:
        itemsActions.updateDrawingItem(layerID, x, y);
        break;

      case constants.MODE_DRAGGING_HOLE:
        holesActions.updateDraggingHole(x, y);
        break;

      case constants.MODE_DRAGGING_LINE:
        linesActions.updateDraggingLine(x, y, state.snapMask);
        break;

      case constants.MODE_DRAGGING_VERTEX:
        verticesActions.updateDraggingVertex(x, y, state.snapMask);
        break;

      case constants.MODE_DRAGGING_ITEM:
        itemsActions.updateDraggingItem(x, y);
        break;

      case constants.MODE_ROTATING_ITEM:
        itemsActions.updateRotatingItem(x, y);
        break;
    }

    viewerEvent.originalEvent.stopPropagation();
  };

  let onMouseDown = (viewerEvent, wasTouch, touchEnd) => {
    let event = viewerEvent.originalEvent;
    let target = viewerEvent.targetOverride || event.target;

    //workaround that allow imageful component to work
    let evt = new Event('mousedown-planner-event');
    evt.viewerEvent = viewerEvent;
    document.dispatchEvent(evt);

    if (!isModeAllowed()) return;

    let { x, y } = mapCursorPosition(viewerEvent);

    if (mode === constants.MODE_IDLE && !designLock) {
      let elementData = extractElementData(target);
      if (!elementData || !elementData.selected) return;

      switch (elementData.prototype) {
        case 'lines':
          linesActions.beginDraggingLine(elementData.layer, elementData.id, x, y, state.snapMask);
          break;

        case 'vertices':
          verticesActions.beginDraggingVertex(elementData.layer, elementData.id, x, y, state.snapMask);
          break;

        case 'items':
          if (elementData.part === 'rotation-anchor')
            itemsActions.beginRotatingItem(elementData.layer, elementData.id, x, y);
          else
            itemsActions.beginDraggingItem(elementData.layer, elementData.id, x, y);
          break;

        case 'holes':
          holesActions.beginDraggingHole(elementData.layer, elementData.id, x, y);
          break;

        default: break;
      }
    }
    event.stopPropagation();
  };

  let onMouseUp = (viewerEvent, wasTouch, touchStart) => {

    let event = viewerEvent.originalEvent;
    let target = viewerEvent.targetOverride || event.target;

    let evt = new Event('mouseup-planner-event');
    evt.viewerEvent = viewerEvent;
    document.dispatchEvent(evt);

    if (!isModeAllowed()) return;

    let { x, y } = mapCursorPosition(viewerEvent);

    switch (mode) {

      case constants.MODE_IDLE:
        let elementData = extractElementData(target);

        if (elementData && elementData.selected) return;

        switch (elementData ? elementData.prototype : 'none') {
          case 'areas':
            areaActions.selectArea(elementData.layer, elementData.id);
            break;

          case 'lines':
            linesActions.selectLine(elementData.layer, elementData.id);
            break;

          case 'holes':
            holesActions.selectHole(elementData.layer, elementData.id);
            break;

          case 'items':
            itemsActions.selectItem(elementData.layer, elementData.id);
            break;

          case 'none':
            projectActions.unselectAll();
            break;
        }
        break;

      case constants.MODE_WAITING_DRAWING_LINE:
        linesActions.beginDrawingLine(layerID, x, y, state.snapMask);
        break;

      case constants.MODE_DRAWING_LINE:
        if (!confirmLengthChk) {
          linesActions.endDrawingLine(x, y, state.snapMask);
          !wasTouch && // don't immedately start new line for touch
            linesActions.beginDrawingLine(layerID, x, y, state.snapMask);
        } else {
          const protoLineID = scene.getIn(['layers', layerID, 'selected', 'lines']).last();
          const protoLine = scene.getIn(['layers', layerID, 'lines', protoLineID]);
          const { x: startX, y: startY } = scene.getIn(['layers', layerID, 'vertices', protoLine.vertices.get(0)]);
          const protoLength = GeometryUtils.pointsDistance(startX, startY, x, y);
          projectActions.setDrawingConfirmationOpen(true);
          setConfirmLengthDialogProto({
            protoLine, layerID,
            snapMask: state.snapMask,
            wasTouch, touchStart,
            x, y, startX, startY,
            protoLength,
          });
          setConfirmLengthDialogValue(
            convert(protoLength)
              .from(scene.get('unit'))
              .to(scene.get('displayUnit'))
          );
        }
        break;

      case constants.MODE_DRAWING_HOLE:
        holesActions.endDrawingHole(layerID, x, y);
        break;

      case constants.MODE_DRAWING_ITEM:
        itemsActions.endDrawingItem(layerID, x, y);
        break;

      case constants.MODE_DRAGGING_LINE:
        linesActions.endDraggingLine(x, y, state.snapMask);
        break;

      case constants.MODE_DRAGGING_VERTEX:
        verticesActions.endDraggingVertex(x, y, state.snapMask);
        break;

      case constants.MODE_DRAGGING_ITEM:
        itemsActions.endDraggingItem(x, y);
        break;

      case constants.MODE_DRAGGING_HOLE:
        holesActions.endDraggingHole(x, y);
        break;

      case constants.MODE_ROTATING_ITEM:
        itemsActions.endRotatingItem(x, y);
        break;
    }

    event.stopPropagation();
  };

  /**
   * Transforms a touch event into mouse event. Modifies the event in place.
   */
  let transformTouch2MouseEvent = (event) => {
    const { originalEvent } = event;
    let target = originalEvent.target;
    var touches = originalEvent.changedTouches,
        first = touches[0],
        type = "";
    switch(originalEvent.type) {
        case "touchstart":  type = "mousedown"; break;
        case "touchmove":   type = "mousemove"; break;
        case "touchend":    type = "mouseup";   break;
        case "touchcancel": type = "mouseup";   break;
        default:           return;
    }
    event.x = event.changedPoints[0].x;
    event.y = event.changedPoints[0].y;
    event.point = event.points[0];
    var simulatedEvent = document.createEvent("MouseEvent");
    // initMouseEvent(type, canBubble, cancelable, view, clickCount,
    //                screenX, screenY, clientX, clientY, ctrlKey,
    //                altKey, shiftKey, metaKey, button, relatedTarget);
    simulatedEvent.initMouseEvent(type, true, true, window, 1,
                                  first.screenX, first.screenY,
                                  first.clientX, first.clientY, false,
                                  false, false, false, 0, target);
    //first.target.dispatchEvent(simulatedEvent);
    event.originalEvent = simulatedEvent;
    event.targetOverride = target;
    return event;
  }

  const SINGLE_PRESS_THRESHHOLD = 250; // ms

  let onTouchStart = (e) => {
    if (!isModeAllowed()) return;
    setLastTouchDown(Date.now());
    transformTouch2MouseEvent(e);
    onMouseDown(e, true);
    setTimeout(() => onMouseUp(e, true, true));
  }

  let onTouchMove = (e) => {
    if (!isModeAllowed()) return;
    transformTouch2MouseEvent(e);
    // TODO: Experimental workaround for panning with touch...does not work well
    // let dx = 0, dy = 0;
    // if (mode2DetectAutopan(mode)) {
    //   const floorPlanViewRect = document.getElementById('floor-plan-view').getBoundingClientRect();
    //   let { clientX: x, clientY: y } = e.originalEvent;
    //   const boundsOffset = 15;
    //   const panSpeed = 10;
    //   x -= floorPlanViewRect.left + rulerSize;
    //   y -= floorPlanViewRect.top + rulerSize;
    //   if (x < boundsOffset) {
    //     dx = panSpeed;
    //   } else if (x > width - rulerSize - boundsOffset) {
    //     dx = -panSpeed;
    //   }
    //   if (y < boundsOffset) {
    //     dy = panSpeed;
    //   } else if (y > height - rulerSize - boundsOffset) {
    //     dy = -panSpeed;
    //   }
    // }
    onMouseMove(e, true);
    // if (dx || dy) {
    //   panViewer(dx, dy);
    // }
  }

  let onTouchEnd = (e) => {
    if (!isModeAllowed()) return;
    const now = Date.now();
    if ((now - lastTouchDown) < SINGLE_PRESS_THRESHHOLD) {
      console.warn('Ignoring touchend event because it was too close to touchstart');
      if (mode == constants.MODE_WAITING_DRAWING_LINE) {
        // Short click while waiting for drawing line.
        // Next tick will be MODE_DRAWING_LINE, so immediately reset to waiting to cancel line draw.
        // Not perfect but it works.
        setTimeout(() => projectActions.setMode(constants.MODE_WAITING_DRAWING_LINE));
      }
      return; // mitigate double-tapping
    }
    transformTouch2MouseEvent(e);
    onMouseDown(e, true, true);
    setTimeout(() => onMouseUp(e, true));
  }

  let onTouchCancel = (e) => {
    return onTouchEnd(e);
  }

  let onChangeValue = (value) => {
    projectActions.updateZoomScale(value.a);
    return viewer2DActions.updateCameraView(value)
  };

  let onChangeTool = (tool) => {
    switch (tool) {
      case TOOL_NONE:
        projectActions.selectToolEdit();
        break;

      case TOOL_PAN:
        viewer2DActions.selectToolPan();
        break;

      case TOOL_ZOOM_IN:
        viewer2DActions.selectToolZoomIn();
        break;

      case TOOL_ZOOM_OUT:
        viewer2DActions.selectToolZoomOut();
        break;
    }
  };

  let onStopDrawing = (event) => {
    projectActions.setMode(constants.MODE_IDLE);
  }

  let onDeleteSelected = (event) => {
    setTimeout(() => projectActions.remove());
  }

  let { e, f, SVGWidth, SVGHeight } = state.get('viewer2D').toJS();

  let rulerSize = 15; //px
  let rulerUnitPixelSize = 152.4; //convert(5).from(constants.UNIT_FOOT).to(constants.UNIT_CENTIMETER);
  let rulerBgColor = SharedStyle.PRIMARY_COLOR.main;
  let rulerFnColor = SharedStyle.COLORS.white;
  let rulerMkColor = SharedStyle.SECONDARY_COLOR.main;
  let sceneWidth = SVGWidth || state.getIn(['scene', 'width']);
  let sceneHeight = SVGHeight || state.getIn(['scene', 'height']);
  let sceneZoom = state.zoom || 1;
  let rulerXElements = Math.ceil( sceneWidth / rulerUnitPixelSize ) + 1;
  let rulerYElements = Math.ceil( sceneHeight / rulerUnitPixelSize ) + 1;

  let rulerTransform = (v) => {
    if (v == null) return v;
    const ft = convert(v).from(constants.UNIT_CENTIMETER).to(constants.UNIT_FOOT);
    return decimalToFtInchesString(ft, { hideInches: true });
  };

  return (
    <div id='floor-plan-view' style={{
      margin: 0,
      padding: 0,
      display: 'grid',
      gridRowGap: '0',
      gridColumnGap: '0',
      gridTemplateColumns: `${rulerSize}px ${width - rulerSize}px`,
      gridTemplateRows: `${rulerSize}px ${height - rulerSize}px`,
      position: 'relative'
    }}>
      <div style={{ gridColumn: 1, gridRow: 1, backgroundColor: rulerBgColor }}></div>
      <div style={{ gridRow: 1, gridColumn: 2, position: 'relative', overflow: 'hidden' }} id="rulerX">
      { sceneWidth ? <RulerX
          unitPixelSize={rulerUnitPixelSize}
          zoom={sceneZoom}
          mouseX={state.mouse.get('x')}
          width={width - rulerSize}
          zeroLeftPosition={e || 0}
          backgroundColor={rulerBgColor}
          fontColor={rulerFnColor}
          markerColor={rulerMkColor}
          positiveUnitsNumber={rulerXElements}
          negativeUnitsNumber={0}
          labelTransform={rulerTransform}
        /> : null }
      </div>
      <div style={{ gridColumn: 1, gridRow: 2, position: 'relative', overflow: 'hidden' }} id="rulerY">
        { sceneHeight ? <RulerY
          unitPixelSize={rulerUnitPixelSize}
          zoom={sceneZoom}
          mouseY={state.mouse.get('y')}
          height={height - rulerSize}
          zeroTopPosition={((sceneHeight * sceneZoom) + f) || 0}
          backgroundColor={rulerBgColor}
          fontColor={rulerFnColor}
          markerColor={rulerMkColor}
          positiveUnitsNumber={rulerYElements}
          negativeUnitsNumber={0}
          labelTransform={rulerTransform}
        /> : null }
      </div>
      <ReactSVGPanZoom
        style={{ gridColumn: 2, gridRow: 2 }}
        width={width - rulerSize}
        height={height - rulerSize}
        value={viewer2D.isEmpty() ? null : viewer2D.toJS()}
        onChangeValue={onChangeValue}
        tool={mode2Tool(mode)}
        onChangeTool={onChangeTool}
        detectAutoPan={mode2DetectAutopan(mode)}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
        onTouchCancel={onTouchCancel}
        // miniaturePosition="none"
        toolbarPosition="none"
      >
        <svg width={scene.width} height={scene.height}>
          <defs>
            <pattern id="diagonalFill" patternUnits="userSpaceOnUse" width="4" height="4" fill="#FFF">
              <rect x="0" y="0" width="4" height="4" fill="#FFF" />
              <path d="M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2" style={{ stroke: '#8E9BA2', strokeWidth: 1 }} />
            </pattern>
          </defs>
          <g style={Object.assign(mode2Cursor(mode), mode2PointerEvents(mode))}>
            <State state={state} catalog={catalog} />
          </g>
        </svg>
      </ReactSVGPanZoom>

      <Dialog
        open={confirmLengthDialog}
        onChange={open => projectActions.setDrawingConfirmationOpen(open)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '0.5em',
          width: '25em',
        }}
        persistent
      >
        <b> Confirm wall length </b>
        <div style={{
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          columnGap: '0.5em',
        }}>
          <div style={{ flex: '1' }}>
            Length
          </div>
          <XDFeetInchesInput
            style={{ flex: '1' }}
            value={confirmLengthDialogValue}
            onChange={value => setConfirmLengthDialogValue(value)}
            immediate
            hideConfirm
            min={0}
          />
        </div>
        <div style={{
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          columnGap: '0.5em',
        }}>
          <div style={{ flex: '2' }}>
            Anchor Point
          </div>
          <Button
            title="Anchor length to the starting point of the line"
            onClick={e => setConfirmLengthDialogAnchor('start')}
            style={{
              backgroundColor: confirmLengthDialogAnchor === 'start' ? SharedStyle.SECONDARY_COLOR.main : 'inherit',
            }}
            baseStyle={{
              flex: '1',
            }}
          >Start (<b>S</b>)</Button>
          <Button
            title="Anchor length to the ending point of the line"
            onClick={e => setConfirmLengthDialogAnchor('end')}
            style={{
              backgroundColor: confirmLengthDialogAnchor === 'end' ? SharedStyle.SECONDARY_COLOR.main : 'inherit',
            }}
            baseStyle={{
              flex: '1',
            }}
          >End (<b>E</b>)</Button>
        </div>
        <div style={{ height: '1em' }}></div>
        <div style={{
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
          columnGap: '0.5em',
        }}>
          <Button
            onClick={e => confirmLineDraw(true)}
            title="Confirm the length and finish drawing walls"
            baseStyle={{width: 'auto'}} size="large" style={{backgroundColor: SharedStyle.SECONDARY_COLOR.main}}
          >
            Confirm + Done
          </Button>
          <Button
            onClick={e => confirmLineDraw()}
            title="Confirm the length and continue drawing walls"
            baseStyle={{width: 'auto'}} size="large" style={{backgroundColor: SharedStyle.SECONDARY_COLOR.main}}
          >
            Confirm
          </Button>
          <Button
            onClick={e => cancelLineDraw(true)}
            title="Cancel confirmation and remove the last wall"
            baseStyle={{width: 'auto'}} size="large"
          >
            Cancel
          </Button>
        </div>
      </Dialog>

    </div>
  );
}


Viewer2D.propTypes = {
  state: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

Viewer2D.contextTypes = {
  viewer2DActions: PropTypes.object.isRequired,
  linesActions: PropTypes.object.isRequired,
  holesActions: PropTypes.object.isRequired,
  verticesActions: PropTypes.object.isRequired,
  itemsActions: PropTypes.object.isRequired,
  areaActions: PropTypes.object.isRequired,
  projectActions: PropTypes.object.isRequired,
  catalog: PropTypes.object.isRequired,
};
